import Link, { LinkProps } from 'next/link';
import React from 'react';
import css from './InlineLink.module.css';

export default function InlineLink({
  children,
  ...props
}: LinkProps & { children: React.ReactNode }) {
  return (
    <Link {...props} className={`${css.linkWrapper} cursor-pointer flex items-center gap-2 relative group `}>
      <span>{children}</span>
      <svg
        viewBox='0 0 24 24'
        className='size-5 stroke-[3px] fill-none stroke-primary-content opacity-20 group-hover:opacity-100 transition-opacity duration-300 ease-in-out'>
        <line
          x1='5'
          y1='12'
          x2='19'
          y2='12'
          className='scale-x-0 translate-x-[10px] group-hover:translate-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out'
        />
        <polyline
          points='12 5 19 12 12 19'
          className='-translate-x-2 group-hover:translate-x-0 transition-transform duration-300 ease-in-out'
        />
      </svg>
    </Link>
  );
}
