'use client';
import Link from 'next/link';
import Logo from '../logos/Logo';
import { ChangeEvent, FormEvent, useState } from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Checkmark from '../Checkmark/Checkmark';

export default function Contact() {
  const [values, setValues] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const inputFieldClass =
    'w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-sm focus:outline-none focus:shadow-outline';
  const labelFieldClass = 'uppercase text-sm font-bold';
  const [sendEmailState, setSendEmailState] = useState<{
    sending: boolean;
    error: Error | null;
    succeed: boolean;
  }>({
    sending: false,
    error: null,
    succeed: false,
  });

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      setSendEmailState({
        sending: true,
        error: null,
        succeed: false,
      });
      await fetch('/api/contact', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSendEmailState({
        sending: false,
        error: null,
        succeed: true,
      });
    } catch (err: unknown) {
      setSendEmailState({
        sending: false,
        error: new Error((err as Error).message),
        succeed: false,
      });
    }
  }
  const handleFieldChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event.target as HTMLInputElement | HTMLTextAreaElement;
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };
  const isFormValid =
    values.fullName &&
    values.email &&
    values.message &&
    !sendEmailState.sending;

  const title = (
    <>
      <h2 className='text-4xl lg:text-5xl font-bold leading-tight'>
        Lets talk about everything!
      </h2>
      <div className='text-gray-700 mt-8'>
        Hate forms? Send us an{' '}
        <Link
          className='font-bold underline'
          href={'mailto:huubinh0409@gmail.com'}
          aria-label="The author\'s email">
          email
        </Link>{' '}
        instead.
      </div>
    </>
  );
  return (
    <section
      className={
        'bg-gray-200 dark:bg-gray-200 h-max w-full overflow-hidden mb-16'
      }
      id='contact'>
      <div className='px-8 py-16 flex flex-col-reverse gap-8 md:flex-row md:px-12 lg:px-16 xl:px-32 mx-auto text-gray-900 rounded-sm shadow-lg'>
        <div className='basis-1/2 hidden md:flex flex-col justify-between gap-8'>
          {title}
          <Logo className={'w-[280px]'} />
        </div>
        <form className='basis-1/2 flex flex-col gap-8' onSubmit={handleSubmit}>
          <div className='block md:hidden'>{title}</div>
          <div>
            <label className={labelFieldClass}>Full Name</label>
            <input
              className={inputFieldClass}
              type='text'
              placeholder=''
              name='fullName'
              onChange={handleFieldChange}
              value={values.fullName}
            />
          </div>
          <div>
            <label className={labelFieldClass}>Email</label>
            <input
              className={inputFieldClass}
              type='text'
              name='email'
              onChange={handleFieldChange}
            />
          </div>
          <div>
            <label className={labelFieldClass}>Message</label>
            <textarea
              className={inputFieldClass}
              name='message'
              onChange={handleFieldChange}
            />
          </div>
          {sendEmailState.error && (
            <p className={'text-error'}>
              {'Something went wrong, please try again!'}
            </p>
          )}
          <button
            className={`mt-8 h-[50px] uppercase text-sm flex justify-center items-center font-bold tracking-wide bg-secondary hover: text-gray-100 p-3 rounded-sm w-full focus:outline-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed
              ${sendEmailState.succeed ? 'bg-green-500' : 'bg-secondary'}
              `}
            type='submit'
            disabled={!isFormValid}>
            {sendEmailState.sending ? (
              <LoadingSpinner />
            ) : sendEmailState.succeed ? (
              <Checkmark />
            ) : (
              'Send Message'
            )}
          </button>
        </form>
      </div>
    </section>
  );
}
