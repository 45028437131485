'use client';
import React, { useState } from 'react';
import { motion, useMotionValueEvent, useScroll } from 'framer-motion';
import css from './ScrollIndicator.module.css';

const ScrollIndicator = ({ className }: { className?: string }) => {
  const [showIndicator, setShowIndicator] = useState(true);
  const { scrollY } = useScroll();
  let scroll = null as any;
  useMotionValueEvent(scrollY, 'change', (latest) => {
    setShowIndicator(false);
    clearTimeout(scroll);
    scroll = setTimeout(() => setShowIndicator(true), 2000);
  });
  return (
    <div
      className={`${css.scrollDownWrapper} ${showIndicator ? '' : '!hidden'}`}>
      <motion.div
        className='w-0.5 h-[100px] mb-2 bg-primary-content origin-top-left'
        initial={{ scaleY: 0, opacity: 0 }}
        animate={{
          scaleY: 1,
          opacity: 0.8,
        }}
        transition={{
          type: 'spring',
          stiffness: 400,
          damping: 40,
          repeat: Infinity,
          repeatType: 'mirror',
          repeatDelay: 1,
        }}
      />
      <motion.div
        className={`${className} ${css.scrollDown} bg-primary-content`}
        initial={{
          y: -150,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 0.8,
        }}
        transition={{
          type: 'spring',
          stiffness: 400,
          damping: 40,
          repeat: Infinity,
          repeatType: 'mirror',
          repeatDelay: 1,
        }}>
        <span className='bg-primary'></span>
      </motion.div>
    </div>
  );
};

export default ScrollIndicator;
