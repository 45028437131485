import Image from 'next/image';

type IPinnedProject = {
  name: string;
  techStack: Array<string>;
  images: Array<string>;
  className: string;
  challenge: string;
};
export default function PinnedProject({
  challenge,
  name,
  techStack,
  images,
  className,
}: Readonly<IPinnedProject>) {
  const [thumbnailImage, ...restImages] = images;
  return (
    <div
      className={
        'min-w-[480px] w-full flex flex-col items-start gap-4 grow ' + className
      }>
      <div className='w-full aspect-video relative max-w-[700px]'>
        <Image
          className='object-contain'
          src={thumbnailImage}
          alt={name}
          fill={true}
        />
      </div>
      <div className='w-full'>
        <h3 className='text-xl lg:text-3xl mb-6 text-primary-content'>
          {name}
        </h3>
        <div className='flex flex-row gap-4'>
          <div className='flex flex-col gap-2'>
            {techStack.map((tech) => (
              <strong
                key={tech}
                className='text-sm font-extralight text-secondary-content'>
                {tech}
              </strong>
            ))}
          </div>
          <p className='text-base font-thin text-secondary-content'>
            {challenge}
          </p>
        </div>
      </div>
      <div className='flex gap-[20px]'>
        <div className='relative w-1/2 aspect-video max-w-[700px]'>
          {restImages.slice(1, 3).map((image) => (
            <Image
              className='object-contain'
              key={image}
              src={image}
              alt={name}
              fill={true}
              sizes='(max-width: 768px) 100vw, 700px'
            />
          ))}
        </div>
      </div>
    </div>
  );
}
