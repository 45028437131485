import InlineLink from '../InlineLink/InlineLink';

const ProjectLineItem = ({ index, name, url }: any) => {
  return (
    <li
      key={index}
      className='text-lg text-secondary-content uppercase py-2 flex items-center max-w-[360px] cursor-pointer'>
      <InlineLink href={url}>
        <strong>{name}</strong>
      </InlineLink>
    </li>
  );
};

export default ProjectLineItem;
