'use client';
import { motion } from 'framer-motion';
import { ThemeToggle } from '../Theme/theme-toggle';
import Logo from '../logos/Logo';

const Header = () => {
  const glassMorphismClass =
    'bg-primary-content-100 px-4 py-2 rounded-md bg-clip-padding' +
    ' backdrop-filter backdrop-blur-md bg-opacity-10 flex items-center justify-center';
  const headerClassName = `sticky top-0 left-0 z-20 flex-nowrap flex-col items-center justify-center h-fit w-full`;

  return (
    <motion.header
      animate={{
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          delay: 2,
        },
      }}
      initial={{
        y: -100,
        opacity: 0,
      }}
      transition={{
        duration: '1s',
      }}
      className={headerClassName}>
      <nav
        className={
          'w-full h-fit flex justify-center' +
          ' items-center' +
          ' bg-transparent'
        }>
        <ul
          className={
            'flex items-center justify-between flex-nowrap h-full' +
            ' w-full px-8 py-4 list-none'
          }>
          <li className={'cursor-pointer'}>
            <a
              aria-label='Scroll to the introduction section'
              href='#intro'
              className={
                'hover:cursor-pointer decoration-none font-bold text-lg h-max w-max' +
                ' align-baseline hover:text-primary-color'
              }>
              <Logo
                className={
                  'w-[32px] h-[32px] md:w-[48px] md:h-[48px] cursor-pointer' +
                  ' [&>g]:fill-primary-content'
                }
              />
            </a>
          </li>
          <li className={'cursor-pointer flex gap-1 md:gap-4'}>
            <motion.a
              className={
                'text-xs text-primary-content md:text-lg ' + glassMorphismClass
              }
              href={
                'https://binh-portfolio-website.s3.ap-southeast-1.amazonaws.com/Binh_Nguyen_SE.pdf'
              }
              rel='noreferrer'
              target={'_blank'}
              whileHover={{
                scale: 1.05,
              }}>
              DOWNLOAD CV
            </motion.a>
            <ThemeToggle />
          </li>
        </ul>
      </nav>
    </motion.header>
  );
};
export default Header;
