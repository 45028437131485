'use client';
import { useRef, useState } from 'react';
import CategorySection from './CategorySection';
import { useScroll } from 'framer-motion';

type IProjectSection = {
  categories: Array<any>;
};

export default function ProjectSection({ categories }: IProjectSection) {
  const [currentCategory, setCurrentCategory] = useState(0);
  const projectSectionRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: projectSectionRef,
  });
  const { projects, name, description } = categories[currentCategory];

  scrollYProgress.on("change", (value) => {
    if (value < 1 / 3) {
      setCurrentCategory(0);
    } else if (value >= 1 / 3 && value < 2 / 3) {
      setCurrentCategory(1);
    } else {
      setCurrentCategory(2);
    }
  });

  return (
    <section className='h-[450vh]' ref={projectSectionRef}>
      <div className='sticky top-0 left-0 z-10 h-screen w-full py-16'>
        <CategorySection
          name={name}
          index={currentCategory}
          description={description}
          projects={projects}
        />
      </div>
    </section>
  );
}
